<template>
  <Layout :title="`${data.address} ${data.addressDetail}`">
    <div class="flex flex-col w-full container">
      <div
        v-for="offer in offers"
        :key="offer.id"
        class="interior-offer-join-item flex flex-row items-center justify-between w-full pb-2"
      >
        <div>
          <h3>{{ offer.hasInteriorOffice.title }}</h3>
          <h4 class="mt-2 mb-2">{{ offer.hasInteriorOffice.explanation }}</h4>
          <h5><span>제안금액</span> {{ offer.price }}만원</h5>
        </div>

        <button
          v-if="offer.isConfirm == false"
          @click="() => onSubmit(offer.id)"
        >
          채택하기
        </button>
        <button class="inactive" v-else>
          채택완료
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import services from "@/services";

export default {
  name: "InteriorOfferJoinList",
  components: {
    Layout,
  },
  data() {
    return {
      data: { address: "", addressDetail: "" },
    };
  },
  mounted() {
    this.getData();
  },
  computed: {
    offers() {
      const { hasInteriorJoinOffers } = this.data;
      if (hasInteriorJoinOffers) {
        return hasInteriorJoinOffers.map((offer) => {
          const newOffer = Object.assign({}, offer);
          newOffer.price = newOffer.price / 10000;
          return newOffer;
        });
      }
      return [];
    },
  },
  methods: {
    async getData() {
      const { id } = this.$route.params;
      const data = await services.interiorOfferDetail(id);
      this.data = data;
    },
    async onSubmit(id) {
      const success = await services.interiorJoinOfferUpdate(id, {
        isConfirm: true,
      });
      if (success) {
        this.$toast.open("채택완료");
        this.$router.go("-1");
      } else {
        this.$toast.open({ message: "채택실패", type: "error" });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.interior-offer-join-item {
  height: 120px;
  border-bottom: 1px solid #e6e6e6;

  h3 {
    font-size: 24px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1.5px;
    text-align: left;
    color: #1a1a1a;
  }

  h4 {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.56px;
    text-align: left;
    color: #737373;
  }

  h5 {
    font-size: 18px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1.5px;
    text-align: left;
    color: #1a1a1a;

    span {
      font-weight: 400;
    }
  }

  button {
    width: 135px;
    height: 46px;
    border-radius: 10px;
    background-color: #283aef;

    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.16px;
    text-align: center;
    color: #fff;

    &.inactive {
      background-color: #c7c7c7;
    }
  }
}

@media (max-width: 639px) {
  .interior-offer-join-item {
    height: auto;
    padding: 15px 0;
    gap: 15px;
    h3 {
      font-size: 14px;
    }
    h4 {
      font-size: 12px;
    }
    h5 {
      font-size: 14px;
    }
    button {
      font-size: 12px;
      height: 35px;
      width: 100px;
    }
  }
}
</style>
